import React, { useEffect } from "react";
import { Header } from "./Header";
import {
  Box,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";

import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  AccountBox,
  AddCircle,
  GroupAdd,
  PersonSearch,
  ArrowDropDown,
  ArrowDropUp,
} from "@mui/icons-material";

type CompanyHeaderProps = {
  open: boolean;
  toggleDrawer: () => void;
  showofferModalProps?: boolean;
  showrecruitModalProps?: boolean;
};

const ListItemLink = styled(Link)`
  color: black;
  text-decoration: none;
`;
export function CompanyHeader({
  open,
  toggleDrawer,
  showofferModalProps = false,
  showrecruitModalProps = false,
}: CompanyHeaderProps) {
  const [showOfferModal, setShowOfferModal] = React.useState(false);
  const [showRecruitModal, setShowRecruitModal] = React.useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(max-width: 768px)").matches) {
        if (open) toggleDrawer(); // openがtrueの場合のみtoggleDrawerを呼び出す
      } else {
        if (!open) toggleDrawer(); // openがtrueの場合のみtoggleDrawerを呼び出す
      }
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [open, toggleDrawer]);

  useEffect(() => {
    setShowOfferModal(showofferModalProps);
    setShowRecruitModal(showrecruitModalProps);
  }, [showofferModalProps, showrecruitModalProps]);

  useEffect(() => {
    if (!open) {
      setShowOfferModal(false);
      setShowRecruitModal(false);
    }
  }, [open]);
  const generateCompanyListItems = () => {
    return (
      <React.Fragment>
        <ListItemLink to="/companycreaterecruit">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" },
              py: 1,
            }}
          >
            <AddCircle
              sx={{
                minWidth: "56px",
                fontSize: "1.7rem",
                color: "rgb(75, 75, 75)",
              }}
            />
            <ListItemButton
              sx={{
                backgroundColor: "rgb(75, 75, 75)",
                ml: 1,
                mr: 2,
                padding: 0,
                borderRadius: 1,
                textAlign: "center",
                "&:hover": { backgroundColor: "rgb(75, 75, 75)" },
              }}
            >
              <ListItemText primary="求人を追加する" sx={{ color: "white" }} />
            </ListItemButton>
          </Box>
        </ListItemLink>
        {/* <ListItemLink to="/sample/companytop">
          <ListItemButton>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="ホーム" />
          </ListItemButton>
        </ListItemLink> */}
        <ListItemLink to="/companyprofiledetail">
          <ListItemButton>
            <ListItemIcon>
              <AccountBox />
            </ListItemIcon>
            <ListItemText primary="企業プロフィール" />
          </ListItemButton>
        </ListItemLink>
        <Box>
          <Box>
            <ListItemButton
              onClick={() => setShowRecruitModal(!showRecruitModal)}
              sx={{
                width: "100%",
                justifyContent: "space-between",
                display: "flex",
                pr: 0,
              }}
            >
              <ListItemIcon>
                <PersonSearch />
              </ListItemIcon>
              <ListItemText primary="求人" />
              <ListItemIcon>
                {showRecruitModal ? (
                  <ArrowDropUp sx={{ fontSize: 32 }} />
                ) : (
                  <ArrowDropDown sx={{ fontSize: 32 }} />
                )}
              </ListItemIcon>
            </ListItemButton>
            {showRecruitModal && (
              <Box
                sx={{
                  width: "90%",
                  marginLeft: "10%",
                }}
              >
                <ListItemLink to="/public-created-recruit">
                  <ListItemButton sx={{ width: "100%", textAlign: "center" }}>
                    <ListItemText primary="作成した求人" />
                  </ListItemButton>
                </ListItemLink>
                <ListItemLink to="/applicant-offer-student">
                  <ListItemButton sx={{ width: "100%", textAlign: "center" }}>
                    <ListItemText primary="応募した学生" />
                  </ListItemButton>
                </ListItemLink>
              </Box>
            )}
            <ListItemButton
              onClick={() => setShowOfferModal(!showOfferModal)}
              sx={{
                width: "100%",
                justifyContent: "space-between",
                display: "flex",
                pr: 0,
              }}
            >
              <ListItemIcon>
                <GroupAdd />
              </ListItemIcon>
              <ListItemText primary="オファー" />
              <ListItemIcon>
                {showOfferModal ? (
                  <ArrowDropUp sx={{ fontSize: 32 }} />
                ) : (
                  <ArrowDropDown sx={{ fontSize: 32 }} />
                )}
              </ListItemIcon>
            </ListItemButton>
            {showOfferModal && (
              <Box
                sx={{
                  width: "90%",
                  marginLeft: "10%",
                }}
              >
                {/* <ListItemLink to="/reverseofferprofilesetup">
                  <ListItemButton
                    sx={{
                      backgroundColor: "rgb(75, 75, 75)",
                      borderRadius: 1,
                      m: 1,
                      py: "5px",
                      px: 0,
                      "&:hover": { backgroundColor: "rgb(75, 75, 75)" },
                    }}
                  >
                    <Typography
                      sx={{
                        color: "white",
                        fontSize: "14px",
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                      }}
                    >
                      逆求人用プロフィール設定
                    </Typography>
                  </ListItemButton>
                </ListItemLink> */}
                <ListItemLink to="/companysearchstudent">
                  <ListItemButton sx={{ width: "100%", textAlign: "center" }}>
                    <ListItemText primary="学生検索" />
                  </ListItemButton>
                </ListItemLink>
                <ListItemLink to="/accepted-student-offer">
                  <ListItemButton sx={{ width: "100%", textAlign: "center" }}>
                    <ListItemText primary="オファーを送信した学生" />
                  </ListItemButton>
                </ListItemLink>
                {/* <ListItemLink to="/companysearchstudent">
                  <ListItemButton>
                    <ListItemText
                      primary="保存した学生"
                      sx={{ width: "100%", textAlign: "center" }}
                    />
                  </ListItemButton>
                </ListItemLink> */}
                <ListItemLink to="/companychat">
                  <ListItemButton>
                    <ListItemText
                      primary="チャット"
                      sx={{ width: "100%", textAlign: "center" }}
                    />
                  </ListItemButton>
                </ListItemLink>
              </Box>
            )}
            {/* <ListItemButton>
              <ListItemIcon>
                <Settings />
              </ListItemIcon>
              <ListItemText primary="設定" />
            </ListItemButton> */}
          </Box>
        </Box>
      </React.Fragment>
    );
  };

  return (
    <Header
      open={open}
      toggleDrawer={toggleDrawer}
      listItems={generateCompanyListItems()}
    />
  );
}
