import * as React from "react";
import Box from "@mui/material/Box";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Typography,
} from "@mui/material";
import { Stepper, Step, StepLabel } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { studentInfoTypes } from "types/page/studentInfoTypes";
import {
  studentDepartmentData,
  studentFacultyData,
} from "data/StudentProfileData";
import { ApplicatedJobType } from "types/page/applicatedJobType";
import { useEffect, useLayoutEffect } from "react";
import { getDownLoadUrlFromFullPath } from "hooks/firebase/context/storage";
import {
  getJobHuntingStudentInfoByUid,
  registerOfferHired,
} from "hooks/firebase/context/offer";
import { DocumentData } from "firebase/firestore";

type StudentProfileProps = {
  studentData: DocumentData;
  selectionData: DocumentData;
};

export default function OfferedStudentProfileCardForChat({
  studentData,
  selectionData,
}: StudentProfileProps) {
  const [isFavorite, setIsFavorite] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [iconValue, setIconValue] = React.useState("");
  const [studentInfo, setStudentInfo] = React.useState<studentInfoTypes>();
  const steps = ["オファー送信", "オファー承認", "採用"];

  const navigate = useNavigate();
  const toggleFavorite = () => {
    setIsFavorite(!isFavorite);
  };

  useEffect(() => {
    (async () => {
      const icon = await getDownLoadUrlFromFullPath(studentData.iconPhtUrl);
      if (icon) {
        setIconValue(icon);
      }
    })();
  }, [studentData.iconPhtUrl]);

  const fetchStudentInfo = () => {
    navigate("/companystudentprofiledetail", {
      state: {
        iconPhtUrl: iconValue,
        senderId: studentData.senderId,
        grade: studentData.grade,
        university: studentData.university,
        faculty: studentData.faculty,
        department: studentData.department,
        firstName: studentData.firstName,
        lastName: studentData.lastName,
        firstNameKana: studentData.firstNameKana,
        lastNameKana: studentData.lastNameKana,
        sex: studentData.sex,
        livingArea: studentData.livingArea,
        birthPlace: studentData.birthPlace,
        graduatedHighSchool: studentData.graduatedHighSchool,
        certification: studentData.certification,
        programmingExperience: studentData.programmingExperience,
        programmingExperienceDetail: studentData.programmingExperienceDetail,
        circle: studentData.circle,
        researchGroup: studentData.researchGroup,
        desiredFirstBusiness: studentData.desiredFirstBusiness,
        desiredSecondBusiness: studentData.desiredSecondBusiness,
        desiredThirdBusiness: studentData.desiredThirdBusiness,
        desiredFirstJob: studentData.desiredFirstJob,
        desiredSecondJob: studentData.desiredSecondJob,
        desiredThirdJob: studentData.desiredThirdJob,
        choiceFirstStandard: studentData.choiceFirstStandard,
        choiceSecondStandard: studentData.choiceSecondStandard,
        choiceThirdStandard: studentData.choiceThirdStandard,
        graduatedYear: studentData.graduatedYear,
        graduatedMonth: studentData.graduatedMonth,
        humanitiesOrScience: studentData.humanitiesOrScience,
        internCompName: studentData.internCompName,
        internJobType: studentData.internJobType,
        internPeriod: studentData.internPeriod,
        selfPRMain: studentData.selfPRMain,
        updatedAt: studentData.updatedAt,
      },
    });
  };

  const sendResult = async () => {
    const errMsg = await registerOfferHired("qR0gERLlBx0yapT6OwL8", "採用");
    console.log(errMsg);
  };

  return (
    <Card
      sx={{
        marginTop: 1,
        width: "100%",
        px: 3,
        borderRadius: 2,
        "& .MuiCardContent-root": {
          py: 2,
        },
      }}
      elevation={0}
    >
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "50%",
            flexWrap: "wrap",
          }}
        >
          <Avatar
            src={iconValue}
            sx={{
              width: 64,
              height: 64,
              marginRight: 1,
            }}
          />
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <CardContent
              sx={{
                px: 1,
                pb: 0,
                maxWidth: 250,
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              <Typography
                noWrap
                component="div"
                fontSize={16}
                fontWeight={"bold"}
              >
                {studentInfo?.lastName} {studentInfo?.firstName}
              </Typography>
              <Typography
                noWrap
                variant="subtitle1"
                component="div"
                fontSize={14}
              >
                早稲田大学
              </Typography>
              <Typography
                noWrap
                variant="subtitle2"
                component="div"
                fontSize={12}
              >
                {studentInfo?.faculty &&
                  studentInfo?.faculty !== 0 &&
                  studentFacultyData[studentInfo?.faculty - 1].value + " "}

                {studentInfo?.department &&
                  studentInfo?.department !== 0 &&
                  studentDepartmentData[studentInfo?.department - 1].value}
              </Typography>
              <Box sx={{ mt: 1, display: "flex" }}>
                <Chip
                  label={"文系"}
                  color={"error"}
                  size="small"
                  sx={{ mr: 1, fontSize: 10 }}
                />
                <Chip
                  label={"24卒"}
                  color={"primary"}
                  size="small"
                  variant="outlined"
                  sx={{ fontSize: 10 }}
                />
              </Box>
            </CardContent>
          </Box>
        </Box>
        <Box
          sx={{
            width: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            sx={{
              width: "100%",
              "& .MuiStepIcon-root.Mui-active": { color: "#00A62E" },
              "& .MuiStepIcon-root.Mui-completed": { color: "#00A62E" },
            }}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>
                  <p style={{ fontSize: 12, fontWeight: "bold" }}>{label}</p>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-around",
          mt: 1,
          mb: 2,
        }}
      >
        <Button
          onClick={fetchStudentInfo}
          variant="contained"
          color="primary"
          size="small"
          sx={{
            backgroundColor: "rgb(75, 75, 75)",
            height: 30,
            width: 100,
            mt: 1,
            "&:hover": { backgroundColor: "rgb(75, 75, 75)" },
          }}
        >
          学生詳細
        </Button>
        <Button
          onClick={() =>
            navigate("/recruit-detail-content", {
              state: {
                trialPeriod: selectionData.trialPeriod,
                access: selectionData.access,
                appealPoint: selectionData.appealPoint,
                daysToRecruit: selectionData.daysToRecruit,
                employmentStatus: selectionData.employmentStatus,
                jobDetail: selectionData.jobDetail,
                jobName: selectionData.jobName,
                maxSalary: selectionData.maxSalary,
                minSalary: selectionData.minSalary,
                neededPersonal: selectionData.neededPersonal,
                offerType: selectionData.offerType,
                others: selectionData.others,
                recruitCatchCopy: "",
                recruitGraduatedYear: selectionData.recruitGraduatedYear,
                recruitedNumber: selectionData.recruitedNumber,
                salaryDetail: selectionData.salaryDetail,
                salaryForm: selectionData.salaryForm,
                treatment: selectionData.treatment,
                workDay: selectionData.workDay,
                workPlace: selectionData.workPlace,
                id: selectionData.templateRef.id,
              },
            })
          }
          variant="contained"
          color="primary"
          size="small"
          sx={{
            backgroundColor: "rgb(75, 75, 75)",
            height: 30,
            width: 100,
            mt: 1,
            "&:hover": { backgroundColor: "rgb(75, 75, 75)" },
          }}
        >
          求人詳細
        </Button>
        <Button
          onClick={fetchStudentInfo}
          variant="contained"
          color="primary"
          size="small"
          sx={{
            backgroundColor: "rgb(75, 75, 75)",
            height: 30,
            width: 100,
            mt: 1,
            "&:hover": { backgroundColor: "rgb(75, 75, 75)" },
          }}
        >
          採用報告
        </Button>
      </Box>
    </Card>
  );
}
