import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import UniversitySelectInput from "components/UniversitySelectInput";
import BunriSelectInput from "components/BunriSelectInput";
import { CircularProgress, Modal, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import GraduateSelectInput from "components/GraduateSelectInput";
import { CompanyHeader } from "components/CompanyHeader";
import StudentProfileCard from "components/StudentProfileCard";
import { useEffect, useLayoutEffect, useState } from "react";
import { useUser } from "hooks/firebase/context/auth";
import styled from "styled-components";
import { studentInfoTypes } from "types/page/studentInfoTypes";
import { searchJobHuntingStudent } from "hooks/firebase/context/offer";
import { DocumentData, QueryDocumentSnapshot } from "firebase/firestore";

const mdTheme = createTheme();

const ModalBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 250px;
  background-color: white;
  border-radius: 20px;
  box-shadow: 24px;
  padding: 40px;
  text-align: center;
`;

export default function CompanySearchStudent() {
  const navigate = useNavigate();

  const [open, setOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [studentInfos, setStudentInfos] = useState<studentInfoTypes[]>();
  const [isEndData, setIsEndData] = useState(false);
  const [universitySelect, setUniversitySelect] = useState("1");
  const [humanitiesOrScience, setHumanitiesOrScience] = useState("1");
  const [graduateSelect, setGraduateSelect] = useState("1");
  const [universityLocalData, setUniversityLocalData] = useState("");
  const [humanitiesOrScienceLocalData, setHumanitiesOrScienceLocalData] =
    useState("");
  const [graduateLocalData, setGraduateLocalData] = useState("");

  useEffect(() => {
    localStorage.setItem("universitySelect", universitySelect);
  }, [universitySelect]);
  useEffect(() => {
    localStorage.setItem("humanitiesOrScience", humanitiesOrScience);
  }, [humanitiesOrScience]);
  useEffect(() => {
    localStorage.setItem("graduate", graduateSelect);
  }, [graduateSelect]);

  useLayoutEffect(() => {
    const university = localStorage.getItem("universitySelect");
    if (university) {
      setUniversitySelect(university);
      setUniversityLocalData(university);
    }
    const humanitiesOrScience = localStorage.getItem("humanitiesOrScience");
    if (humanitiesOrScience) {
      setHumanitiesOrScience(humanitiesOrScience);
      setHumanitiesOrScienceLocalData(humanitiesOrScience);
    }
    const graduate = localStorage.getItem("graduate");
    if (graduate) {
      setGraduateSelect(graduate);
      setGraduateLocalData(graduate);
    }
  }, []);

  const [lastVisibleData, setLastVisibleData] =
    useState<QueryDocumentSnapshot<DocumentData> | null>(null);

  const toggleDrawer = () => {
    setOpen(!open);
  };
  const user = useUser();
  const fetchStudentInfos = async (
    university: number,
    humanitiesOrScience: number,
    graduatedYear: number
  ) => {
    setIsLoading(true);
    try {
      const { students, lastVisible } = await searchJobHuntingStudent(
        university,
        humanitiesOrScience,
        graduatedYear
      );

      if (students.length < 10) {
        setIsEndData(true);
      } else {
        setIsEndData(false);
        setLastVisibleData(lastVisible);
      }
      setStudentInfos(students as studentInfoTypes[]);
    } catch (error) {
      console.error("Failed to fetch student infos:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getExtraStudent = async () => {
    if (lastVisibleData) {
      setIsLoading(true);
      try {
        const { students, lastVisible } = await searchJobHuntingStudent(
          Number(universitySelect),
          Number(humanitiesOrScience),
          Number(graduateSelect),
          lastVisibleData
        );
        if (students.length < 10) {
          setIsEndData(true);
        } else {
          setIsEndData(false);
          setLastVisibleData(lastVisible);
        }
        setStudentInfos((prev = []) => [
          ...prev,
          ...(students as studentInfoTypes[]),
        ]);
      } catch (error) {
        console.error("Failed to fetch student infos:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (user != null) {
      fetchStudentInfos(1, 1, 1);
    }
  }, [user]);

  const mainStyles = {
    width: open ? "calc(100vw - 240px)" : "calc(100vw - 72px)",
    marginLeft: open ? "240px" : "72px",
    transition: ".3s",
    overflow: "auto",
  };
  return (
    <ThemeProvider theme={mdTheme}>
      <CssBaseline />
      <CompanyHeader
        open={open}
        toggleDrawer={toggleDrawer}
        showofferModalProps={true}
      />
      <Modal open={isLoading}>
        <ModalBox sx={{ width: { xs: "300px", sm: "350px" } }}>
          <Typography
            sx={{
              fontSize: { xs: "1.2rem", sm: "1.7rem" },
              fontWeight: "bold",
            }}
            pt={2}
            pb={5}
          >
            データ取得中
          </Typography>
          <CircularProgress size={"3rem"} />
        </ModalBox>
      </Modal>
      <Box
        component="main"
        sx={{
          ...mainStyles,
        }}
      >
        {/* <Box
          sx={{
            width: { xs: 242, sm: 302 },
            height: 42,
            borderRadius: 20,
            marginTop: { xs: 10, sm: 12 },
            border: "1px solid #4b4b4b",
          }}
          mx="auto"
        >
          <Button
            variant="contained"
            disableElevation
            sx={{
              width: { xs: 120, sm: 150 },
              borderRadius: 20,
              height: 40,
              fontSize: "1.2rem",
              fontWeight: "bold",
              backgroundColor: "#4b4b4b",
              ":hover": { backgroundColor: "#4b4b4b" },
            }}
          >
            学生
          </Button>
          <Button
            onClick={() => navigate("/companysearchclub")}
            variant="contained"
            disableElevation
            sx={{
              width: { xs: 120, sm: 150 },
              borderRadius: 20,
              color: "#4b4b4b",
              fontWeight: "bold",
              fontSize: "1.2rem",
              height: 40,
              backgroundColor: "rgba(0,0,0,0)",
              ":hover": { backgroundColor: "rgba(0,0,0,0)" },
            }}
          >
            サークル
          </Button>
        </Box> */}

        {/* <Paper
          component="form"
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: 500,
            mx: "auto",
            mt: 5,
            borderRadius: 20,
            boxShadow: 0,
            border: "1px solid black",
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="キーワード検索  例)TOEIC、早稲田大学"
            inputProps={{ "aria-label": "search google maps" }}
          />
          <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Paper> */}
        <Box
          sx={{
            display: "flex",
            width: "80%",
            height: "160px",
            flexWrap: "wrap",
            mx: "auto",
            justifyContent: "space-around",
            mt: 16,
          }}
        >
          <UniversitySelectInput
            setUniversitySelecProps={setUniversitySelect}
            defaultValue={universityLocalData}
          />
          <BunriSelectInput
            setHumanitiesOrScienceProps={setHumanitiesOrScience}
            defaultValue={humanitiesOrScienceLocalData}
          />
          <GraduateSelectInput
            setGraduateSelectProps={setGraduateSelect}
            defaultValue={graduateLocalData}
          />
          <IconButton
            sx={{
              width: 40,
              height: 40,
              mt: 2,
              backgroundColor: "#4b4b4b",
              "&:hover": { backgroundColor: "#4b4b4b" },
            }}
            aria-label="search"
            onClick={() => {
              if (universitySelect && humanitiesOrScience && graduateSelect)
                fetchStudentInfos(
                  Number(universitySelect),
                  Number(humanitiesOrScience),
                  Number(graduateSelect)
                );
            }}
          >
            <SearchIcon sx={{ color: "white" }} />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "90%",
            flexWrap: "wrap",
            mx: "auto",
            mt: 5,
          }}
        >
          {studentInfos &&
            studentInfos.map((studentInfo, i) => {
              return (
                <StudentProfileCard key={i} StudentProfileInfos={studentInfo} />
              );
            })}
        </Box>
        <Box sx={{ mb: 5 }}>
          {!isEndData && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                onClick={getExtraStudent}
                disableElevation
                style={{
                  width: 250,
                  fontWeight: "bold",
                  borderRadius: 5,
                  marginTop: 30,
                  marginBottom: 8,
                  height: 40,
                  backgroundColor: "#434343",
                }}
              >
                次の10件
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </ThemeProvider>
  );
}
