import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import styled from "styled-components";
import { useState, useLayoutEffect, useEffect, useRef } from "react";
import {
  List,
  ListItem,
  ListItemText,
  TextareaAutosize,
  Typography,
  IconButton,
  Button,
  Modal,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { useUser } from "hooks/firebase/context/auth";
import {
  collection,
  doc,
  DocumentData,
  DocumentReference,
  onSnapshot,
  orderBy,
  query,
  Timestamp,
  Unsubscribe,
} from "firebase/firestore";
import { db } from "hooks/firebase/main";
import ChatCell from "components/ChatCell";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { CompanyHeader } from "components/CompanyHeader";
import PreviewSnackbar from "components/PreviewSnackbar";
import OfferedStudentProfileCardForChat from "components/OfferedStudentProfileCardForChat";
import {
  getChatList,
  getChatRelatedData,
  makeReadStamp,
  sendMessage,
} from "hooks/firebase/context/chatBetCompStd";
import {
  getJobHuntingStudentInfoByUid,
  registerOfferHired,
} from "hooks/firebase/context/offer";

const TextareaStyle = styled.textarea`
  width: 95%;
  font-size: 16px;
  padding: 10px 50px 10px 10px;
  border-radius: 15px;
  background-color: lightgray;
  outline: none;
  border: none;
  resize: none;
`;
const MessageContainer = styled(Box)`
  padding: 13px;
  width: fit-content;
  /* @media screen and (max-width: 600px) {
    width: 280px;
  } */
`;
const OhterMessageContainer = styled(MessageContainer)`
  white-space: pre-wrap;
  border-radius: 20px 20px 20px 0;
  background-color: lightgray;
`;
const MyMessageContainer = styled(MessageContainer)`
  white-space: pre-wrap;
  border-radius: 20px 20px 0 20px;
  background-color: lightgreen;
  margin-right: 10px;
  margin-left: auto;
`;
const ModalBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 250px;
  background-color: white;
  border-radius: 20px;
  box-shadow: 24px;
  padding: 40px;
  text-align: center;
`;
type MessageContentType = {
  message: string;
  time: Date;
  uid: string;
  senderId: string;
  isLog: boolean;
};
type ChatRoomType = {
  senderId: string;
  iconPhtRef: string;
  lastMessage: string;
  name: string;
  roomID: string;
  unRead: number;
  updatedAt: Timestamp;
};

export default function CompanyChat() {
  const user = useUser();
  const myUid = user?.auth.uid;
  const [showDetailView, setShowDetailView] = useState<boolean>(false);
  const [messageList, setMessageList] = useState<MessageContentType[]>([]);
  const [chatTimelineList, setChatTimelineList] = useState<ChatRoomType[]>([]);
  const [unsubscribeMessageListener, setUnsubscribeMessageListener] =
    useState<Unsubscribe | null>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [roomId, setRoomId] = useState("");
  const [senderId, setSenderId] = useState("");
  const [messageValue, setMessageValue] = useState("");
  const [isOfferChat, setIsOfferChat] = useState(false);
  const scrollBottomRef = useRef<HTMLDivElement>(null);
  const [selectionRefefence, setSelectionRefefence] =
    useState<DocumentReference<DocumentData>>();
  const [studentId, setStudentId] = useState("");
  const [chatSelectionData, setChatSelectionData] = useState<DocumentData>();
  const [chatStudentData, setChatStudentData] = useState<DocumentData>();

  useEffect(() => {
    const fetchData = async () => {
      if (user) {
        const { errMsg, chatLists, isOffer } = await getChatList(user.auth.uid); // Corrected chatList to chatLists
        console.log("chatlist", chatLists);

        setIsOfferChat(isOffer);

        if (chatLists && errMsg === "") {
          type ChatType = {
            id: string;
            lastMessage?: string;
            student: {
              iconPhtUrl?: string;
              name?: string;
            };
            roomDoc: {
              id: string;
            };
            unRead?: number;
            updatedAt?: Timestamp;
            progressSelection?: {
              path?: string;
            };
          };

          const formattedChatLists = (chatLists as ChatType[]).map(
            (chat: ChatType) => ({
              senderId: chat.id || "",
              iconPhtRef: chat.student.iconPhtUrl || "",
              lastMessage: chat.lastMessage || "",
              name: chat.student.name || "",
              roomID: chat.roomDoc.id || "",
              unRead: chat.unRead || 0,
              updatedAt: chat.updatedAt || Timestamp.now(),
            })
          );
          setChatTimelineList(formattedChatLists);
        } else {
          console.log(errMsg);
        }
      }
    };
    fetchData();
  }, [roomId, user]);

  useEffect(() => {
    scrollBottomRef?.current?.scrollIntoView();
  }, [messageList, setMessageList]);

  useEffect(() => {
    const fetchData = async () => {
      if (selectionRefefence && studentId) {
        const { errMsg, selectionData, studentData } = await getChatRelatedData(
          selectionRefefence,
          studentId
        );
        if (selectionData) setChatSelectionData(selectionData);
        if (studentData) setChatStudentData(studentData);
        if (errMsg !== "") {
          console.log(errMsg);
        }
        setIsLoading(false);
      }
    };
    fetchData();
  }, [selectionRefefence, studentId]);

  const setMessageId = (item: ChatRoomType) => {
    setRoomId(item.roomID);
    setSenderId(item.senderId);
  };

  useEffect(() => {
    if (roomId) {
      const unsubscribe = onSnapshot(
        query(
          collection(db, "chatBetCompStd", roomId, "messages"),
          orderBy("sendedAt")
        ),
        (snapshot) => {
          const messageArr: MessageContentType[] = [];
          snapshot.forEach((doc) => {
            if (doc.data().selectionRef !== null)
              setSelectionRefefence(doc.data().selectionRef);
            if (doc.data().isSenderStudent) {
              setStudentId(doc.data().senderID);
            } else {
              setStudentId(doc.data().readerID);
            }
            const messages: MessageContentType = {
              message: "",
              time: new Date(),
              uid: "",
              senderId: "",
              isLog: false,
            };
            if (doc.data().messageType === 2) {
              messages.isLog = true;
              switch (doc.data().registerdStatus) {
                case "created":
                  messages.message = isOfferChat
                    ? "オファーが送信されました"
                    : "学生が求人に応募しました";
                  break;
                case "progress":
                  messages.message = isOfferChat
                    ? "オファーが承認されました"
                    : "学生が書類選考を通過しました";
                  break;
                case "expired":
                  messages.message = "オファーの期限が切れました";
                  break;
                case "rejected":
                  messages.message = "学生がオファーを辞退しました";
                  break;
                case "documentRejected":
                  messages.message = "書類審査での採用が見送られました";
                  break;
                case "hired":
                  messages.message = "『選考結果：採用』を登録しました";
                  break;
                case "notHired":
                  messages.message = "『選考結果：不採用』を登録しました";
                  break;
                case "withdrawWhileProgress":
                  messages.message = "『選考中の辞退』を登録しました";
                  break;
                case "withdrawAfterHired":
                  messages.message = "選考結果が採用から辞退に変更されました";
                  break;
                default:
                  break;
              }
            } else {
              messages.message = doc.data().message;
            }
            messages.uid = doc.id;
            messages.senderId = doc.data().senderID;
            messageArr.push(messages);
          });
          setMessageList(messageArr);
        }
      );
      setUnsubscribeMessageListener(() => unsubscribe);
      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      };
    }
  }, [isOfferChat, roomId]);
  const onSend = async () => {
    if (user) {
      const errMsg = await sendMessage(1, user.auth.uid, messageValue, roomId);
      if (errMsg === "") {
        setMessageValue("");
      } else {
        console.log(errMsg);
      }
    }
  };
  const toggleShowDetail = () => {
    setShowDetailView(!showDetailView);
  };

  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  // const getDetailStudentInfo = async (studentId, id) => {
  //   await getChatRelatedData(studentId, id);
  // };
  const mainStyles = {
    width: open ? "calc(100vw - 240px)" : "calc(100vw - 72px)",
    marginLeft: open ? "240px" : "72px",
    transition: ".3s",
    overflow: "auto",
  };
  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        backgroundColor: "#ebebeb",
      }}
    >
      <CompanyHeader
        open={open}
        toggleDrawer={toggleDrawer}
        showofferModalProps={true}
      />
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        sx={{
          pt: 7,
          ...mainStyles,
        }}
      >
        <Container component="main" disableGutters maxWidth={false}>
          <CssBaseline />
          <Box sx={{ display: "flex" }}>
            <Box
              sx={{
                display: { xs: showDetailView ? "none" : "block", md: "block" },
                width: { xs: "100%", md: "430px" },
                px: 1,
                pt: 2,
                height: "calc(100vh - 82px)",
                mr: 2,
              }}
            >
              <List
                sx={{
                  width: "100%",
                  maxHeight: "calc(100vh - 82px)",
                  overflow: "scroll",
                  bgcolor: "background.paper",
                  borderRadius: 2,
                }}
              >
                {chatTimelineList.map((item, index) => {
                  return (
                    <Button
                      key={index}
                      onClick={() => {
                        setMessageId(item);
                        setShowDetailView(!showDetailView);
                      }}
                      sx={{
                        textTransform: "unset",
                        width: "100%",
                        justifyContent: "space-between",
                      }}
                    >
                      <ChatCell
                        item={item}
                        chatTimelineList={chatTimelineList}
                      />
                    </Button>
                  );
                })}
              </List>
            </Box>
            <Box
              sx={{
                display: { xs: showDetailView ? "block" : "none", md: "block" },
                width: {
                  md: "80%",
                  lg: open ? "calc(100vw - 700px)" : "calc(100vw - 550px)",
                },
                mx: "auto",
              }}
            >
              <Box
                sx={{
                  display: { xs: "block", md: "none" },
                  position: "fixed",
                  zIndex: 3,
                  top: 80,
                  left: 85,
                }}
              >
                <IconButton
                  onClick={toggleShowDetail}
                  color="default"
                  aria-label="back"
                  sx={{ width: 35, height: 35 }}
                >
                  <FontAwesomeIcon icon={faChevronLeft} />
                </IconButton>
              </Box>

              <List
                sx={{
                  mt: 1,
                  width: "100%",
                  overflow: "scroll",
                  p: 0,
                }}
              >
                {chatStudentData && chatSelectionData && (
                  <OfferedStudentProfileCardForChat
                    studentData={chatStudentData}
                    selectionData={chatSelectionData}
                  />
                )}
                <Box
                  sx={{
                    backgroundColor: "white",
                    mt: 1,
                    height: "calc(100vh - 380px)",
                    mb: 2,
                  }}
                >
                  {messageList.map(
                    (messagecontent: MessageContentType, i: number) => {
                      return (
                        <ListItem key={i} sx={{ paddingBottom: 0 }}>
                          {/* {myUid === messagecontent.senderId ? (
                          <Box />
                        ) : (
                          <ListItemAvatar sx={{ marginTop: "auto" }}>
                            <Avatar
                              alt="Travis Howard"
                              src={iconPropValue}
                              sx={{
                                width: 50,
                                height: 50,
                                marginLeft: 1,
                              }}
                            />
                          </ListItemAvatar>
                        )} */}
                          <ListItemText
                            primary={
                              <React.Fragment>
                                {messagecontent.isLog ? (
                                  <Box
                                    sx={{
                                      width: "80%",
                                      mx: "auto",
                                      height: 25,
                                      borderRadius: 20,
                                      alignItems: "center",
                                      justifyContent: "center",
                                      display: "flex",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: 14,
                                        fontWeight: "bold",
                                        color: "rgb(75, 75, 75)",
                                      }}
                                    >
                                      {messagecontent.message}
                                    </Typography>
                                  </Box>
                                ) : myUid === messagecontent.senderId ? (
                                  <MyMessageContainer>
                                    {messagecontent.message}
                                  </MyMessageContainer>
                                ) : (
                                  <OhterMessageContainer>
                                    {messagecontent.message}
                                  </OhterMessageContainer>
                                )}
                              </React.Fragment>
                            }
                          />
                        </ListItem>
                      );
                    }
                  )}
                </Box>
                <div ref={scrollBottomRef} />
              </List>
              <Box
                sx={{
                  position: { xs: "block", md: "fixed" }, // 位置を固定
                  bottom: 10, // ページの最下部に配置
                  right: 10, // 右端までの幅を確保
                  width: {
                    md: open ? "calc(100vw - 560px)" : "calc(100vw - 410px)",
                    lg: open ? "calc(100vw - 700px)" : "calc(100vw - 550px)",
                  },
                  display: "flex",
                  backgroundColor: "white", // 背景色を白に設定
                  borderTop: "1px solid #ccc", // 上部に境界線を追加
                  padding: "10px", // パディングを追加して内部コンテンツとの間隔を設定
                  boxSizing: "border-box", // ボックスサイズをborder-boxに設定
                  borderRadius: 3,
                  mx: "auto",
                }}
              >
                <TextareaStyle
                  rows={3}
                  placeholder="メッセージを入力"
                  autoComplete="off"
                  style={{ backgroundColor: "white", flexGrow: 1 }} // flexGrowを追加して、テキストエリアが利用可能なスペースを埋めるように設定
                  value={messageValue}
                  onChange={(e) => setMessageValue(e.target.value)}
                />
                <Box sx={{ alignSelf: "center" }}>
                  <IconButton
                    onClick={() => {
                      onSend();
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faPaperPlane}
                      color="rgb(29, 155, 240)"
                      style={{ width: "20px", height: "20px" }}
                    />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}
